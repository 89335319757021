import { hot } from "react-hot-loader/root";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { UIContextProvider } from "./components/context/UIContext";
import MyLaundryManager from "./lib/localStorage/MyLaundryManager";
import AppLessLayout from "./components/layout/Layout";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/scss/globals.scss";
import Index from "./partials";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BaseRoomSummaryRoute,
  LaundryRoute,
  MachineRoute,
  MachineRouteParamName,
  MyLaundryRoute,
  NFCRoute,
  NFCRouteParamName,
  PressStartRoute,
  QRRoute,
  QRRouteParamName,
  RequestRefundRoute,
  RequestServiceRoute,
  RoomSummaryRoute,
  StartedRoute,
  StudentPaymentRoute,
  ThreeDSRRouteSlug,
  AccountDeletionRoute,
} from "./routes/routes";
import MyLaundry from "./partials/laundry";
import { NFC } from "./partials/NFC";
import { QR } from "./partials/QR";
import PressStart from "./partials/PressStart";
import FourOFour from "./partials/404";
import Started from "./partials/Started";
import RoomSummary from "./partials/RoomSummary";
import Loading from "./components/Loading";
import RequestService from "./partials/RequestService";
import RequestRefund from "./partials/RequestRefund";
import MachineView from "./partials/MachineView";
import logger from "./lib/logger";
import StickyMessageHOC from "./components/alerts/StickyMessageHOC";
import SummaryEntry from "./partials/RoomSummaryEntry";
import StudentPayment from "./partials/StudentPayment/StudentPayment";
import AccountDeletion from "./components/account-deletion";
import {
  createRemoteConfigService,
  RemoteConfigService,
} from "./lib/firebase/remoteConfigService";
const getEventUrl = (): string | undefined => {
  const location = MyLaundryManager.getLocation();
  const room = MyLaundryManager.getRoom();
  if (location?.locationId) {
    let url = `${process.env.EVENT_API_URL}?location_id=${location.locationId}`;

    if (room?.roomId) {
      url += `&room_id=${room.roomId}`;
    }
    return url;
  }
};

const fatal = () => {
  if (!process.env.EVENT_API_URL) {
    logger.error("EVENT_API_URL is not defined, functionality will be limited");
    throw new Error("Fatal configuration error");
  }
  if (!process.env.CLIENT_SERVICES_BASE_URL) {
    logger.fatal("EVENT_API_URL is not defined. Nothing will work.");
    throw new Error("Fatal configuration error");
  }
};

const App = () => {
  fatal();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const restLink = new RestLink({
    uri: `${process.env.CLIENT_SERVICES_BASE_URL}/api/v1/`,
  });
  // this is the Example of how to use the remote config service to get a flag from firebase and use it
  // useEffect(() => {
  //   const service: RemoteConfigService = createRemoteConfigService();
  //   async function init() {
  //     await service.fetchAndActivate();
  //     const flag = service.getBoolean("flag");
  //     if (flag) {
  //       alert("Flag is true");
  //     } else {
  //       alert("Flag is false");
  //     }
  //   }
  //   init();
  // }, []);

  const ContextHOC: FunctionComponent<{ guid?: string }> = (
    props: PropsWithChildren<{ guid?: string }>
  ) => {
    return (
      <UIContextProvider id={props.guid} eventUrl={getEventUrl()}>
        {props.children}
        <>
          <StickyMessageHOC />
        </>
      </UIContextProvider>
    );
  };

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      setClient(
        new ApolloClient({
          link: restLink,
          cache,
        })
      );
    }

    init().catch((error) => {
      logger.error("Error initializing ApolloClient!");
      throw error;
    });
  }, []);

  if (!client) {
    return (
      <AppLessLayout noContext={true}>
        <Loading />
      </AppLessLayout>
    );
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          {/* Homepage */}
          <Route
            path="/"
            element={
              <ContextHOC>
                <Index />
              </ContextHOC>
            }
          />
          <Route
            path={`/${LaundryRoute}`}
            element={
              <ContextHOC>
                <Index />
              </ContextHOC>
            }
          />

          {/* Laundry page (roll of machines) */}
          <Route
            path={`/${MyLaundryRoute}`}
            element={
              <ContextHOC>
                <MyLaundry />
              </ContextHOC>
            }
          />

          {/* Machine info (shown when machine is idle) */}
          <Route
            element={
              <ContextHOC>
                <MachineView is3DSreturn={false} />
              </ContextHOC>
            }
            path={`/${MachineRoute}/${MachineRouteParamName}`}
          />

          {/* Student Payment (shown when machine is idle) */}
          <Route
            element={
              <ContextHOC>
                <StudentPayment />
              </ContextHOC>
            }
            path={`/${StudentPaymentRoute}/${MachineRouteParamName}`}
          />

          {/* 3DS response route */}
          <Route
            element={
              <ContextHOC>
                <MachineView is3DSreturn={true} />
              </ContextHOC>
            }
            path={`/${MachineRoute}/${MachineRouteParamName}/${ThreeDSRRouteSlug}`}
          />

          {/* Press start */}
          <Route
            path={`/${PressStartRoute}/${MachineRouteParamName}`}
            element={
              <ContextHOC>
                <PressStart />
              </ContextHOC>
            }
          />

          {/* Started */}
          <Route
            path={`/${StartedRoute}/${MachineRouteParamName}`}
            element={
              <ContextHOC>
                <Started is3DSreturn={false} />
              </ContextHOC>
            }
          />

          {/* 3DS return url */}
          <Route
            path={`/${StartedRoute}/${MachineRouteParamName}/${ThreeDSRRouteSlug}`}
            element={
              <ContextHOC>
                <Started is3DSreturn={true} />
              </ContextHOC>
            }
          />

          {/* Room Summary */}
          <Route
            path={`/${RoomSummaryRoute}`}
            element={
              <ContextHOC>
                <SummaryEntry />
              </ContextHOC>
            }
          />
          <Route path={`/${BaseRoomSummaryRoute}`} element={<RoomSummary />} />

          {/* NFC redirect (doesn't need context?) */}
          <Route path={`/${NFCRoute}/${NFCRouteParamName}`} element={<NFC />} />

          {/* QR redirect (doesn't need context?) */}
          <Route path={`/${QRRoute}/${QRRouteParamName}`} element={<QR />} />

          {/* Request service */}
          <Route
            path={`/${RequestServiceRoute}`}
            element={
              <ContextHOC>
                <RequestService />
              </ContextHOC>
            }
          />

          {/* Request refund */}
          <Route
            path={`/${RequestRefundRoute}`}
            element={
              <ContextHOC>
                <RequestRefund />
              </ContextHOC>
            }
          />

          {/* Account Deletion */}
          <Route
            path={`/${AccountDeletionRoute}`}
            element={
              <ContextHOC>
                <AccountDeletion />
              </ContextHOC>
            }
          />

          {/* Catch-all route, if not defined, then 404 */}
          <Route path={"*"} element={<FourOFour />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default hot(
  withLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_KEY ?? "",
  })(App)
);
