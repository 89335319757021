import { gql } from "@apollo/client";
import { onAdyenPaymentSubmitError } from "../../types/FrontEnd/Adyen";
import { VendMethod } from "../../types/FrontEnd/vend-price";
import { PaymentMethods } from "@adyen/adyen-web/dist/types/types";
import { paymentMethods } from "../../types/FrontEnd/Payment";

export interface AdyenPaymentDetailsVars {
  body: {
    detailsRequest: {
      details: {
        MD: string;
        PaRes: string;
      };
    };
    metadata: {
      method: paymentMethods;
      roomId: string;
      locationId: string;
      licensePlate: string;
      additionalBlocks?: number;
    };
  };
}

//@todo split this into successful vs challenge response
export interface AdyenPaymentDetailsDataProps {
  id: string;
  ts: string;
  name: string;
  data: {
    paymentResponse: {
      additionalData: any;
      amount: {
        currency: string;
        value: number;
      };
      merchantReference: string;
      pspReference: string;
      resultCode: string;
    };
    source: "webapp";
  };
}

export interface AdyenPaymentDetailsData {
  data: AdyenPaymentDetailsDataProps;
}

const ADYEN_PAYMENT_DETAILS = gql`
  mutation AdyenPaymentDetails {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/startMachineWithAdyenPaymentDetails"
        method: "POST"
        bodyKey: "body"
      ) {
      id
      ts
      name
      data
    }
  }
`;

export default ADYEN_PAYMENT_DETAILS;
