import React, { useEffect, useState } from "react";
import GenericModal, {
  buttonType,
  modalType,
} from "../../request-service/generic-modal";
import { useTranslation } from "react-i18next";

export interface ErrorModalProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
  copy?: string;
  subCopy?: string;
  modalType?: StudentPaymentError;
}

export enum StudentPaymentError {
  insufficientBalance,
  somethingWentWrong,
}

/**
 * Higher order component for generic modal, sets error state defaults
 * @param props
 * @constructor
 */
const StudentErrorModal = (props: ErrorModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [close, setClose] = useState<any>("");
  const [genericErrorCopy, setGenericErrorCopy] = useState<any>("");
  const [insufficientFundsErrorCopy, setInsufficientFundsErrorCopy] =
    useState<any>("");
  const [genericErrorTitle, setGenericErrorTitle] = useState<any>("");
  const [insufficientFundsErrorTitle, setInsufficientFundsErrorTitle] =
    useState<any>("");
  useEffect(() => {
    setClose(t("StudentErrorModal.close"));
    setGenericErrorCopy(t("StudentErrorModal.genericErrorCopy"));
    setInsufficientFundsErrorCopy(
      t("StudentErrorModal.insufficientFundsErrorCopy")
    );
    setGenericErrorTitle(t("StudentErrorModal.genericErrorTitle"));
    setInsufficientFundsErrorTitle(
      t("StudentErrorModal.insufficientFundsErrorTitle")
    );
  });

  const getCopy = (): string => {
    if (props.copy) {
      return props.copy;
    } else if (props.modalType === StudentPaymentError.insufficientBalance) {
      return insufficientFundsErrorCopy;
    } else {
      return genericErrorCopy;
    }
  };

  const getTitle = (): string => {
    if (props.title) {
      return props.title;
    } else if (props.modalType === StudentPaymentError.insufficientBalance) {
      return insufficientFundsErrorTitle;
    } else {
      return genericErrorTitle;
    }
  };

  return (
    <GenericModal
      subCopy={props.subCopy}
      buttons={[
        {
          text: "Close",
          type: buttonType.primary,
          onClick: props.closeModal,
        },
      ]}
      showModal={props.showModal}
      closeModal={props.closeModal}
      title={getTitle()}
      copy={getCopy()}
      type={modalType.error}
    />
  );
};

export default StudentErrorModal;
