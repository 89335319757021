import "@adyen/adyen-web/dist/adyen.css";
import DropinComponent from "@adyen/adyen-web/dist/types/components/Dropin/components/DropinComponent";
import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import GET_ADYEN_PAYMENT_METHODS, {
  MachinePriceEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetAdyenPaymentMethods";
import MyLaundryManager from "../../lib/localStorage/MyLaundryManager";
import logger from "../../lib/logger";
import {
  AdyenPaymentSubmitErrorResponse,
  onAdyenPaymentProps,
  onAdyenPaymentResponseProps,
} from "../../lib/types/FrontEnd/Adyen";
import { VendPrice } from "../../lib/types/FrontEnd/vend-price";
import {
  FlashMessageLocalStorageName,
  FlashMessageProps,
  StickyMessageType,
} from "../alerts/StickyMessage";
import { UIContext } from "../context/UIContext";
import AdyenPaymentManager from "../payment/adyen-payment-manager";
import { PaymentCompleteProps } from "./machine-top-off-menu";
import { paymentMethods as PaymentMethodTypes } from "../../lib/types/FrontEnd/Payment";
import {
  getOnPaymentAttemptEventPayload,
  getOnPaymentStartEventPayload,
  getPaymentErrorEventPayload,
  getPaymentSuccessEventPayload,
} from "../tracking/SegmentEvents";
import ErrorModalPayment from "../modals/error-modal__payment";
import {
  MachineRoute,
  StartedRoute,
  ThreeDSAdditionalBlocksUrlParameter,
  ThreeDSDurationUrlParameter,
  ThreeDSRRouteSlug,
} from "../../routes/routes";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { useTranslation } from "react-i18next";
import useFeatureFlags from "../../lib/flags/useFeatureFlags";

export type paymentCompleteCallback = ({
  success,
  result,
}: {
  success: boolean;
  result: any;
}) => Promise<PaymentCompleteProps | void>;

interface MachinePaymentLinkProps {
  price: VendPrice;
  asyncCallback: paymentCompleteCallback;
  displayAdyenForm: boolean;
  licensePlate?: string;
}

export interface adyenPaymentResult {
  id: string;
  name: string;
  success: boolean;
}

const MachinePaymentLink: React.FC<MachinePaymentLinkProps> = ({
  price,
  asyncCallback,
  displayAdyenForm,
  licensePlate,
}) => {
  const { state, dispatch } = useContext(UIContext);
  if (price == undefined) {
    throw new Error("Missing price information");
  }
  if (!state.getSourceMachine) {
    throw new Error("getSourceMachine is not defined on state!");
  }
  let tempMachine: Machine | null | undefined;

  if (licensePlate) {
    tempMachine = state.getMachine(licensePlate);
  } else {
    tempMachine = state.getSourceMachine();
  }

  if (!tempMachine) {
    throw new Error("Missing machine information");
  }
  const machine = tempMachine;
  const licensePlateForThisMachine = machine.licensePlate;
  const adyenContainer = useRef(null);
  const adyenDropin = useRef<DropinComponent>(
    null
  ) as React.MutableRefObject<DropinComponent>;
  const myLaundryManager = new MyLaundryManager();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [adyenError, setAdyenError] = useState<
    AdyenPaymentSubmitErrorResponse | undefined
  >();
  const client = useApolloClient();

  useEffect(() => {
    // Don't show if we're not ready
    if (!price || !displayAdyenForm || !state.initialized) return;

    if (!price.topOffDuration?.zeroDecimalAmount) {
      // Weird, why are we only sending this event when we have don't have a top off?
      // Because the price changes when the top-off is init-ed which leads to a bunch of duplicate events for top-offs, instead we manually fire that event when the user commits the amount of time to add, and hits the button that displays the adyen payment form
      dispatch({
        type: "log-event",
        payload: getOnPaymentStartEventPayload({
          type: price.method,
          amount: price.zeroDecimalAmount,
          Added_Time: price.topOffDuration?.duration,
          processor: "A",
        }),
      });
    }
  }, [price, displayAdyenForm]);

  const onSubmitHandler = (props: onAdyenPaymentProps) => {
    dispatch({
      type: "log-event",
      payload: getOnPaymentAttemptEventPayload({
        ...props,
        Added_Time: price.topOffDuration?.duration,
      }),
    });
  };

  const successHandler = async (
    result: onAdyenPaymentResponseProps | undefined
  ) => {
    dispatch({
      type: "log-event",
      payload: getPaymentSuccessEventPayload(
        {
          method: price.method,
          duration: price.topOffDuration?.duration,
          zeroDecimalAmount: price.zeroDecimalAmount,
        },
        result?.paymentMethod
      ),
    });

    if (price.additionalBlocks) {
      logger.debug(
        "Additional blocks found (top-up), setting sticky message localStorage"
      );
      const messageToFlashOnNextPage: FlashMessageProps = {
        type: StickyMessageType.topOffCompleteNotice,
        text: `${price.topOffDuration?.duration} ${t(
          "StudentPaymentForm.minAdded"
        )}`,
      };
      localStorage.setItem(
        FlashMessageLocalStorageName,
        JSON.stringify(messageToFlashOnNextPage)
      );
    }

    if (!licensePlateForThisMachine) {
      throw new Error("License plate is not defined!");
    }
    if (!state.location === undefined) {
      throw new Error("location is not defined!");
    }
    myLaundryManager
      .addMachine(licensePlateForThisMachine, state.location!)
      .then(() => {
        asyncCallback({
          success: result?.success ?? false,
          result: { ...result, method: PaymentMethodTypes.creditcard },
        });
      });
  };

  const errorHandler = async (
    result: AdyenPaymentSubmitErrorResponse | undefined
  ): Promise<void> => {
    dispatch({
      type: "log-event",
      payload: getPaymentErrorEventPayload(
        { ...price, duration: price.topOffDuration?.duration },
        result
      ),
    });

    const errorMessage = result?.message;
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setAdyenError(result as AdyenPaymentSubmitErrorResponse);
    } else {
      setErrorMessage("Error");
      setAdyenError({
        id: "",
        message: "Error",
        name: "Error",
        paymentMethod: undefined,
        statusCode: "10000",
        success: false,
        ts: "",
        errorCode: "Error",
      });
    }

    await asyncCallback({ success: false, result });
  };

  const isBrowserSafari = (): boolean => {
    const userAgentString = window.navigator.userAgent;
    return !!userAgentString.match(/Version\/[\d.]+.*Safari/);
  };

  const getBlockedPaymentMethodsForClient = (): string => {
    if (isBrowserSafari()) {
      return "googlepay,paywithgoogle";
    }
    return "";
  };

  /**
   * Get valid adyen payment methods from next.js backend
   * Adyen will send client browser info from the client to this endpoint, and will return what payment methods the drop-in should include
   * For example: users on safari will get apple/google/cc as payment options, but chrome users will only see google/cc
   */
  async function getPaymentMethods(): Promise<MachinePriceEndpointResponseGraphql> {
    return client
      .query<MachinePriceEndpointResponseGraphql>({
        query: GET_ADYEN_PAYMENT_METHODS,
        variables: {
          blockedPaymentMethods: getBlockedPaymentMethodsForClient(),
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((err) => {
        logger.error("Unable to get adyen payment methods!", err);
        throw new Error("Failed to fetch payment methods.");
      });
  }

  /**
   * So if adyen throws a 3ds challenge on payment, we need to provide a proper return url
   * Which url to return them to depends on where they are in the application
   * Currently, we are hard-coding these return urls to return you to the "started" page if you have blocks to add (topping up/off), and "machine" if you are not (assuming you made the payment from the machine view)
   */
  const getAdyen3DSReturnUrl = (): string => {
    if (price.additionalBlocks) {
      return (
        window.location.origin +
        `/${StartedRoute}/${machine.opaqueId}/${ThreeDSRRouteSlug}?${ThreeDSAdditionalBlocksUrlParameter}=${price.additionalBlocks}&${ThreeDSDurationUrlParameter}=${price.topOffDuration?.duration}`
      );
    }
    const returnUrl =
      window.location.origin +
      `/${MachineRoute}/${machine.opaqueId}/${ThreeDSRRouteSlug}`;
    logger.trace("3ds return url: %s", returnUrl);
    return returnUrl;
  };

  const flags = useFeatureFlags();

  useEffect(() => {
    if (!adyenContainer.current || !displayAdyenForm) return;
    // Adyen web lib is dependent on the browser environment so we import it JIT
    // and pass it as the first arg to our wrapper constructor

    getPaymentMethods().then((paymentMethods) => {
      import("@adyen/adyen-web").then((AdyenCheckout) => {
        // adyenInstance.unmount(adyenContainer.current);
        const adyenInstance = new AdyenPaymentManager(
          AdyenCheckout.default,
          {
            billingAddressRequired: flags.adyenBillingAddressRequired,
            amount: {
              value: price.zeroDecimalAmount,
              currency: price.currency.toUpperCase(), // Adyen expects currency code in upper case
              type: price.method,
            },
            metadata: {
              method: PaymentMethodTypes.creditcard,
              roomId: price.roomId,
              locationId: price.locationId,
              licensePlate: licensePlateForThisMachine,
              accountId: null,
              additionalBlocks: price.additionalBlocks,
              returnUrl: getAdyen3DSReturnUrl(),
            },
            setStatusAutomatically: false,
            returnUrl: window.location.origin + window.location.pathname,
            errorHandler,
            successHandler,
            onSubmitHandler,
          },
          adyenDropin,
          setErrorMessage,
          paymentMethods.methods,
          client
        );

        if (adyenContainer?.current) {
          adyenInstance.mount(adyenContainer.current);
        }
      });
    });
  }, [adyenContainer.current, price, displayAdyenForm]);

  const { t } = useTranslation();
  return (
    <>
      {adyenError?.statusCode && adyenError?.errorCode && (
        <ErrorModalPayment
          error={adyenError}
          closeModal={() => {
            setErrorMessage("");
          }}
          showModal={!!errorMessage}
        />
      )}

      {displayAdyenForm && (
        <div className={`payment-link-row`}>
          <div className="payment-link-row__title">
            {t("MachinePaymentLink.paymentMethod")}
          </div>

          <div className={`payment-link-row__wrap`}>
            <div ref={adyenContainer} />
          </div>
        </div>
      )}
    </>
  );
};

export default MachinePaymentLink;
