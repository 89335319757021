import React, { useEffect, useState } from "react";
import GenericModal, { buttonType, modalType } from "./generic-modal";
import { UIContext } from "../context/UIContext";
import EmailUsLink from "../../constants/EmailUsLink";
import { useTranslation } from "react-i18next";
import {
  OnErrorModalCloseEventPayload,
  OnErrorModalContactEventPayload,
} from "../tracking/SegmentEvents";

interface ErrorModalProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
  copy?: string;
  subCopy?: string;
}

/**
 * Higher order component for generic modal, sets error state defaults
 * @param props
 * @constructor
 */

const ErrorModal = (props: ErrorModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [contactUs, setContactUs] = useState<any>("");
  const [close, setClose] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [copy, setCopy] = useState<any>("");
  useEffect(() => {
    setContactUs(t("ErrorModal.contactUs"));
    setClose(t("ErrorModal.close"));
    setTitle(t("ErrorModal.title"));
    setCopy(t("ErrorModal.copy"));
  });
  const { dispatch } = React.useContext(UIContext);

  return (
    <GenericModal
      subCopy={props.subCopy}
      buttons={[
        {
          text: contactUs,
          type: buttonType.primary,
          href: EmailUsLink,
          onClick: () => {
            dispatch({
              type: "log-event",
              payload: OnErrorModalContactEventPayload,
            });
          },
        },
        {
          text: close,
          type: buttonType.secondary,
          onClick: () => {
            dispatch({
              type: "log-event",
              payload: OnErrorModalCloseEventPayload,
            });
          },
        },
      ]}
      showModal={props.showModal}
      closeModal={props.closeModal}
      title={title || props.title}
      copy={copy || props.copy}
      type={modalType.error}
    />
  );
};

ErrorModal.defaultProps = {
  title: "Request Failed",
  copy: "We’re sorry, something went wrong with your request. Please try again or contact us for help.",
};

export default ErrorModal;
